import * as React from "react";

import styled, { keyframes } from "styled-components";

import CircleText from "../components/CircleText";
import Form from "../components/form";

import flowersMobileLeft from "../images/flowers-dark_mobile.png";
import flowersTabletLeft from "../images/flowers-dark_tablet.png";
import flowersDesktopLeft from "../images/flowers-dark_desktop-landscape.png";
import flowersMobileRight from "../images/flowers-light_mobile.png";
import flowersTabletRight from "../images/flowers-light_tablet.png";
import flowersDesktopRight from "../images/flowers-light_desktop-landscape.png";

import Logo from "../images/Logo.png";
import CircleImg from "../images/circle.png";

import "../main.css";
import { Helmet } from "react-helmet";
import { Link } from "gatsby";

const Layout = styled.div`
  width: 100vw;
  height: 100%;
  min-height: 100vh;

  padding: 40px;

  background-color: #131f3c;

  position: relative;

  > main {
    margin: 0 auto;
    max-width: 768px;

    color: #f6f2ed;

    h2 {
      font-family: "Ayer Poster Cursive Web Regular";
      font-style: italic;
      font-weight: normal;
      font-size: 28px;
      line-height: 110%;

      color: #f6f2ed;

      margin: 0;
      margin-top: 16px;
      margin-bottom: 12px;
      letter-spacing: 0.01em;

      -webkit-font-smoothing: antialiased;
    }

    p {
      font-family: MaisonNeue, sans-serif;
      font-style: normal;
      font-weight: 500;
      font-size: 14px;
      line-height: 110%;

      letter-spacing: 0.05em;

      color: #f6f2ed;
      -webkit-font-smoothing: antialiased;
    }
  }
`;

const DisplayText = styled.p`
  font-family: "Ayer Poster Cursive Web Regular";
  font-style: italic;
  font-weight: normal;
  font-size: 36px;
  line-height: 110%;

  color: #f6f2ed;

  margin: 0;
  margin-bottom: 12px;
  letter-spacing: 0.01em;

  -webkit-font-smoothing: antialiased;

  @media (min-width: 768px) {
    font-size: 32px;
  }
`;

const LogoImage = styled.img`
  display: block;

  object-fit: contain;
  width: 100%;
  max-width: 180px;

  margin: 0 auto;

  margin-bottom: 40px;
`;

// markup
const IndexPage = () => {
  return (
    <Layout>
      <Helmet>
        <title>Terms of Service | BLVD PL</title>
      </Helmet>
      <main>
        <Link href="/">
          <LogoImage src={Logo} alt="BLVD Pl" />
        </Link>
        <DisplayText as="h1">Terms of Service</DisplayText>
        <div>
          <p>
            BLVD PL, LLC (the "Company") maintains its website, www.BLVDPL.COM,
            (the "Site") for your personal entertainment, information,
            education, and for the sale of goods and services. Your access to
            and use of the Site is subject to the following terms and conditions
            and all applicable laws. By accessing and browsing the Site, you
            accept these Terms of Service.
          </p>

          <h2>Restrictions on Use</h2>
          <p>
            Everything you see or read on the Site is the property of the
            Company or of the Company's partners, and is protected by copyright
            and trademark laws. Images of people or places displayed on the Site
            are either the property of, or used with permission by, the Company.
            The use of these images by you, or anyone else authorized by you, is
            prohibited unless specifically permitted by these Terms of Service
            or specific written permission is provided by the Company. The
            trademarks, logos, and service marks (collectively, the
            "Trademarks") displayed on the Site, are registered and unregistered
            Trademarks of the Company and others. You may not use the Trademarks
            without the written permission of the Company. To notify the Company
            of an alleged copyright infringement found on the Site, please
            contact the Company immediately.
          </p>

          <h2>Disclaimers</h2>
          <p>
            The Site is provided to you as-is. The Company does not warrant the
            accuracy of material posted on the Site, either expressly or by
            implication, for any particular purpose and expressly disclaims any
            warranties of merchantability, fitness for a particular purpose, or
            noninfringement, with respect to the use of or contents of the Site
            or any information, goods or services that are available,
            advertised, or sold through the Site. Neither the Company, nor any
            of its affiliates, directors, officers, or employees, or any
            third-party vendor is liable or responsible for any kind of loss or
            damage that you incur relating to use of the Site, whether or not
            the circumstances may have been within the control of the Company or
            of any vendor providing such services or services support. The
            Company also assumes no responsibility, and shall not be liable for
            any damages to, or viruses that may infect, your computer equipment
            or other property on account of your access to, use of, or browsing
            in the Site or downloading of any materials, data, text, images,
            video, or audio from the Site.
          </p>

          <h2>Third-Party Hyperlinks</h2>
          <p>
            The Company may host hyperlinks on the Site that point to other
            sites on the internet maintained by third parties. These hyperlinks
            do not indicate the Company's endorsement or sponsorship of, or our
            affiliation with, such sites, and the Company neither has control
            over, nor is responsible for, their content. Other sites may link to
            the Site. The Company reserves the right to require termination of
            any link any to the Site, at its sole discretion at any time.
          </p>

          <h2>Personal Information</h2>
          <p>
            You may voluntarily provide your email address and other personal
            information to the Company via this Site. You must provide your
            true, accurate, current, and complete information.
          </p>

          <h2>Miscellaneous</h2>
          <p>
            These Terms of Service will be governed by and construed in
            accordance with the laws of the State of California, without regard
            to the any principles of conflicts of law rules. You agree that any
            litigation that arises out of or related to these Terms of Service
            will be filed only in the state or federal courts located in Los
            Angeles County, California and you hereby consent and submit to the
            personal jurisdiction of such court for the purposes of litigation.
            If any of these Terms of Service are found unlawful, void, or for
            any reason unenforceable, then that provision will be considered
            severable from the remaining terms and conditions and will not
            affect the validity and enforceability of the remaining provisions.
            The Company may at any time revise these Terms of Service by
            updating this posting. You are bound by any such revisions and
            should therefore periodically visit this page to review the
            then-current Terms of Service.
          </p>
        </div>
      </main>
    </Layout>
  );
};

export default IndexPage;
